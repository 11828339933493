import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`The Premier Chatbot and Voice Assistant Conference`}</h2>
    <p>{`Dashbot’s annual conference, SuperBot 2019, is just around the corner on April 2nd in San Francisco. If you are interested in, or working on, chatbots, voice assistants, or AI, this is the conference for you. We have assembled a great group of speakers to meet with, learn from, and share with.`}</p>
    <p>{`We have an amazing lineup of speakers including leading experts from Google Assistant, Amazon Alexa, IBM, Slack, Twilio, Intuit, the BBC, the NBA, Discovery Channel, Assist, Earplay, OctaneAI, and many more.`}</p>
    <p>{`We’re expecting a great turnout as well. Last year, we had over 400 attendees ranging from Fortune 500 enterprises, leading digital agencies, and exciting startups to individual developers and enthusiasts.`}</p>
    <p>{`Check out the video below for a quick overview:`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/R3sqmhjGrQc?feature=oembed" width="1200"></iframe>
    <h2>{`Benefits of attending`}</h2>
    <p>{`We have assembled a world class group of speakers in an amazing venue in San Francisco.`}</p>
    <p>{`SuperBot is an opportunity to learn:`}</p>
    <ul>
      <li parentName="ul">{`How to increase engagement, user satisfaction, and monetization in your chatbots and voice skills`}</li>
      <li parentName="ul">{`Best practices in conversational design`}</li>
      <li parentName="ul">{`How to improve user acquisition and discoverability`}</li>
      <li parentName="ul">{`Tips on creating personality and branding`}</li>
      <li parentName="ul">{`How and why enterprises are leveraging chatbots and voice assistants`}</li>
      <li parentName="ul">{`The latest in customer service chatbots`}</li>
      <li parentName="ul">{`What are investors looking for in this space`}</li>
      <li parentName="ul">{`and much more!!`}</li>
    </ul>
    <h2>{`Speakers`}</h2>
    <p>{`Our Speakers include experts from Google Assistant, Amazon Alexa, Twilio, IBM, Slack, the BBC, Discovery Channel, Assist, Earplay, RAIN, and more!`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/03/07161744/speaker-logos.png",
        "alt": null
      }}></img></p>
    <h2>{`Insights from last year`}</h2>
    <p>{`Check out some of the panels from last year below, or view more videos on the SuperBot website.`}</p>
    <h3>{`Enterprise Brands Conversational Strategies:`}</h3>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/Je7VQCHPkc0?feature=oembed" width="1200"></iframe>
    <h3>{`The Future of Voice:`}</h3>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/soX5ntOT_Lc?feature=oembed" width="1200"></iframe>
    <h3>{`Scaling Customer Service:`}</h3>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/sOgvlWeoXgU?feature=oembed" width="1200"></iframe>
    <h3>{`Investor insights:`}</h3>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/zaa9OV2U9Ls?feature=oembed" width="1200"></iframe>
    <h2>{`Special offer`}</h2>
    <p>{`As a special offer for Dashbot readers, use the discount code “dashbotrocks” for 25% off at `}<a parentName="p" {...{
        "href": "https://sb2019.dashbot.io."
      }}>{`https://sb2019.dashbot.io.`}</a></p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      